import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { getAddBlogDispatcher, getDeleteSubscriberDispatcher, getAddSubscriberDispatcher, getAddMediaDispatcher, getAddVideoDispatcher, getBlogDetailDispatcher, getBlogsDispatcher, getCategoryDataDispatcher, getDashboardDataDispatcher, getDeleteBlogDispatcher, getDeleteVideoDispatcher, getMediaDataDispatcher, getSubscriberDispatcher, getUpdateBlogDispatcher, getUpdateVideoDispatcher, getVideoDetailDispatcher, getVideosDispatcher } from "./dashboard-dispatchers";

const initialState: DashboardDataType = {
  mySubscribers: [],
  myBlogs: [],
  myVideos: [],
  categories: [],
  myMedia: [],
  loading: false,
  totalBlogPage: 1,
  totalVideoPage: 1,
  totalMediaPage: 1,
  totalSubscriberPage: 1,
  dashboardData: {
    blog: 0,
    vlog: 0,
    subscriber: 0,
    course: 0
  }
};

export const dashboardSlice = createSlice({
  name: "dashboardReducer",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriberDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getSubscriberDispatcher.fulfilled, (state: any, action: any) => {
        if (action.payload?.data?.status) {
          state.mySubscribers = action?.payload?.data?.data?.Subscriber
          state.totalSubscriberPage = action?.payload?.data?.data?.totalPage
        }
        state.loading = false;
      })
      .addCase(getSubscriberDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
    builder
      .addCase(getBlogsDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getBlogsDispatcher.fulfilled, (state: any, action: any) => {
        if (action.payload?.data?.status) {
          state.myBlogs = action?.payload?.data?.data?.blogs
          state.totalBlogPage = action?.payload?.data?.data?.totalPage
        }
        state.loading = false;
      })
      .addCase(getBlogsDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
    builder
      .addCase(getAddBlogDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getAddBlogDispatcher.fulfilled, (state: any, action: any) => {
        state.loading = false;
      })
      .addCase(getAddBlogDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
    builder
      .addCase(getUpdateBlogDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getUpdateBlogDispatcher.fulfilled, (state: any, action: any) => {
        state.loading = false;
      })
      .addCase(getUpdateBlogDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
    builder
      .addCase(getDeleteBlogDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getDeleteBlogDispatcher.fulfilled, (state: any, action: any) => {
        state.loading = false;
      })
      .addCase(getDeleteBlogDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
    builder
      .addCase(getVideosDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getVideosDispatcher.fulfilled, (state: any, action: any) => {
        if (action.payload?.data?.status) {
          state.myVideos = action?.payload?.data?.data?.vlog
          state.totalVideoPage = action?.payload?.data?.data?.totalPage
        }
        state.loading = false;
      })
      .addCase(getVideosDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
    builder
      .addCase(getAddVideoDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getAddVideoDispatcher.fulfilled, (state: any, action: any) => {
        state.loading = false;
      })
      .addCase(getAddVideoDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
    builder
      .addCase(getUpdateVideoDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getUpdateVideoDispatcher.fulfilled, (state: any, action: any) => {
        state.loading = false;
      })
      .addCase(getUpdateVideoDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
    builder
      .addCase(getDeleteVideoDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getDeleteVideoDispatcher.fulfilled, (state: any, action: any) => {
        state.loading = false;
      })
      .addCase(getDeleteVideoDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
    builder
      .addCase(getVideoDetailDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getVideoDetailDispatcher.fulfilled, (state: any, action: any) => {
        state.loading = false;
      })
      .addCase(getVideoDetailDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
    builder
      .addCase(getBlogDetailDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getBlogDetailDispatcher.fulfilled, (state: any, action: any) => {
        state.loading = false;
      })
      .addCase(getBlogDetailDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
    builder
      .addCase(getDashboardDataDispatcher.fulfilled, (state: any, action: any) => {
        if (action.payload?.data?.status) {
          state.dashboardData = action?.payload?.data?.data
        }
      })
    builder
      .addCase(getCategoryDataDispatcher.fulfilled, (state: any, action: any) => {
        if (action.payload?.data?.status) {
          state.categories = action?.payload?.data?.data?.category
        }
      })
      builder
      .addCase(getMediaDataDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getMediaDataDispatcher.fulfilled, (state: any, action: any) => {
        if (action.payload?.data?.status) {
          state.myMedia = action?.payload?.data?.data?.Media
          state.totalMediaPage = action?.payload?.data?.data?.totalPage
        }
        state.loading = false;
      })
      .addCase(getMediaDataDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
      builder
      .addCase(getAddMediaDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getAddMediaDispatcher.fulfilled, (state: any, action: any) => {
        state.loading = false;
      })
      .addCase(getAddMediaDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
      builder
      .addCase(getAddSubscriberDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getAddSubscriberDispatcher.fulfilled, (state: any, action: any) => {
        console.log(action)
        state.loading = false;
      })
      .addCase(getAddSubscriberDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
      builder
      .addCase(getDeleteSubscriberDispatcher.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getDeleteSubscriberDispatcher.fulfilled, (state: any, action: any) => {
        console.log(action)
        state.loading = false;
      })
      .addCase(getDeleteSubscriberDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
  },
});
export const { setLoading } = dashboardSlice.actions;

export default dashboardSlice.reducer;
