import { CopyToClipboard } from "react-copy-to-clipboard";
import React from "react";
import { toast } from "react-toastify";
import { pdfjs, Document, Page } from "react-pdf";
import ReactPlayer from "react-player";

interface MediaModalProps {
  onPress: (val: string) => void;
  data: any;
}

export default function MediaModal(props: MediaModalProps) {
  const [copyText, setCopyText] = React.useState(false);
  const [numPages, setNumPages] = React.useState<number>();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  if (copyText) {
    toast.success("copied", {});
  }

  const getItemRender = () => {
    if (String(props?.data?.url)?.toLocaleLowerCase()?.includes(".pdf")) {
      return (
        <div className="flex w-full justify-center border-1 border-[#D3D3D3] rounded-[22px]">
          <Document
            file={props?.data?.url}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((pages) => (
                <Page
                  className="w-full flex"
                  pageNumber={pages}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              ))}
          </Document>
        </div>
      );
    }
    if (String(props?.data?.url)?.toLocaleLowerCase()?.includes(".mp4")) {
      return (
        <div className="flex w-full justify-center border-1 border-[#D3D3D3] rounded-[22px] overflow-hidden">
          <ReactPlayer
            className="react-player"
            url={props?.data?.url}
            width="100%"
            height="100%"
            playing={true}
            controls
          />
        </div>
      );
    }
    return (
      <div className="flex w-full justify-center border-1 border-[#D3D3D3] rounded-[22px]">
        <img
          src={props?.data?.url}
          alt="Flowbite Logo"
          className="rounded-2xl scale-100 aspect-[6/4] w-full object-cover"
        />
      </div>
    );
  };

  return (
    <>
      <div className="flex fixed inset-0 z-40 backdrop-brightness-50 outline-none focus:outline-none items-center">
        <div className="flex relative my-6 mx-auto w-2/3 justify-center px-2 overflow-auto h-[90%]">
          <div className="p-8 bg-white border-0 rounded-xl shadow-md relative flex flex-col w-full overflow-scroll  outline-none focus:outline-none  items-center h-full">
            <div className="w-full px-2 py-4">
              {getItemRender()}
              <div className="py-4">
                <p className="font-poppins text-[16px] font-bold leading-loose text-black">
                  {props?.data?.title}
                </p>
                <CopyToClipboard
                  text={props?.data?.url}
                  onCopy={() => setCopyText(true)}
                >
                  <p
                    className="leading-loose break-words text-blue-500 "
                    id="holdtext"
                  >
                    {props?.data?.url}
                  </p>
                </CopyToClipboard>
              </div>
            </div>
            <div className="flex items-center justify-between w-1/2">
              <div className="flex">
                <button
                  onClick={() => props?.onPress?.("cancel")}
                  className="bg-gradient-to-r from-[#14ADD6] to-[#384295] p-[1px] rounded-[10px] focus:outline-none w-[200px]"
                >
                  <span className="bg-gradient-to-r from-[#14ADD6] to-[#384295] font-bold block text-white hover:text-[#384295] px-4 py-[10px] bg-white rounded-[10px] hover:from-white hover:to-white font-nunito cursor-pointer">
                    Cancel
                  </span>
                </button>
              </div>
              <div className="flex">
                <button
                  onClick={() => props?.onPress?.("delete")}
                  className="bg-gradient-to-r from-[#F08989] to-[#AB0606] p-[1px] rounded-[10px] focus:outline-none w-[200px]"
                >
                  <span className="bg-gradient-to-r from-[#F08989] to-[#AB0606] font-bold block text-white hover:text-[#384295] px-4 py-[10px] rounded-[10px] hover:from-white hover:to-white font-nunito cursor-pointer">
                    Delete
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
