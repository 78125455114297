import React from "react"
import { useAppDispatch, useAppSelector } from "../store/hooks"
import { getSubscriberDispatcher } from "../store/slices/dashboard-slice/dashboard-dispatchers"
import { getSubscriberSelector } from "../store/slices/dashboard-slice/dashboard-selectors"
import moment from "moment"

export default function SubscribersTable () {
  const mySubscribers = useAppSelector(getSubscriberSelector)
  const dispatcher = useAppDispatch()

  React.useEffect(() => {
    dispatcher(getSubscriberDispatcher("page=1&pageSize=5"))
  }, [ dispatcher ])

  return (
    <>
      <div className="flex-col space-x-6 pl-6 pr-3 mt-6 pb-8 w-1/2">
        <div className="w-full relative overflow-x-auto p-6 bg-white rounded-3xl shadow-[0.0px_4.0px_4.0px_rgba(0,0,0,0.20)]">
          <div className="flex w-full justify-between pb-5">
            <h5 className="tracking-tight text-[#121212] font-nunito font-extrabold text-[26px]">Subscribers</h5>
          </div>
          <table className="w-full text-sm text-left text-gray-500">
            <thead className=" text-md text-[#515151]">
              <tr>
                <th scope="col" className="px-2 py-3 font-nunito font-extrabold">
                  S/N
                </th>
                <th scope="col" className="px-2 py-3 font-nunito font-extrabold">
                  Email
                </th>
                <th scope="col" className="px-2 py-3 font-nunito font-extrabold">
                  Created At
                </th>
              </tr>
            </thead>
            <tbody>
              { mySubscribers?.map((item: any, index: number) => (
                <tr key={ item?._id } className="bg-white border-b">
                  <th scope="row" className="px-2 py-4 font-medium text-[#272525] whitespace-nowrap text-[12px]">
                    { `${index + 1 > 9 ? (index + 1) : "0" + (index + 1)}` }
                  </th>
                  <td className="px-2 py-4 font-medium text-[#272525] whitespace-nowrap text-[12px]">
                    { item?.email }
                  </td>
                  <td className="px-2 py-4 font-medium text-[#272525] whitespace-nowrap text-[12px]">
                    { `${moment(item?.createdAt).format("DD/MM/YYYY")} at ${moment(item?.createdAt).format("hh:mm A")}` }
                  </td>
                </tr>
              )) }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
