import { createAsyncThunk } from "@reduxjs/toolkit"
import Services from "../../../network/services/v1"
import { AppDispatch } from "../../store"
import { setLoading } from "./dashboard-slice"

export const setLoadingDispatcher =
  (value: boolean) => (dispatch: AppDispatch) => {
    return dispatch({ type: setLoading.type, payload: value })
  }

export const getSubscriberDispatcher: any = createAsyncThunk("getSubscribers", async (request: string) => {
  try {
    return await Services.DashboardService.Subscribers(request)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getAddSubscriberDispatcher: any = createAsyncThunk("getAddSubscribers", async (request: string) => {
  try {
    return await Services.DashboardService.AddSubscribers(request)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getDeleteSubscriberDispatcher: any = createAsyncThunk("getDeleteSubscribers", async (request: string) => {
  try {
    return await Services.DashboardService.DeleteSubscribers(request)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

// Blogs Dispatchers
export const getBlogsDispatcher: any = createAsyncThunk("getBlogs", async (request: string) => {
  try {
    return await Services.DashboardService.Blogs(request)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getAddBlogDispatcher: any = createAsyncThunk("getAddBlog", async (body: any) => {
  try {
    return await Services.DashboardService.AddBlog(body)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getUpdateBlogDispatcher: any = createAsyncThunk("getUpdateBlog", async (body: any) => {
  try {
    return await Services.DashboardService.UpdateBlog(body)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getDeleteBlogDispatcher: any = createAsyncThunk("getDeleteBlog", async (body: any) => {
  try {
    return await Services.DashboardService.DeleteBlog(body)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

// Video Dispatchers
export const getVideosDispatcher: any = createAsyncThunk("getVideos", async (request: string) => {
  try {
    return await Services.DashboardService.Videos(request)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getAddVideoDispatcher: any = createAsyncThunk("getAddVideo", async (body: any) => {
  try {
    return await Services.DashboardService.AddVideo(body)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getUpdateVideoDispatcher: any = createAsyncThunk("getUpdateVideo", async (body: any) => {
  try {
    return await Services.DashboardService.UpdateVideo(body)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getDeleteVideoDispatcher: any = createAsyncThunk("getDeleteVideo", async (body: any) => {
  try {
    return await Services.DashboardService.DeleteVideo(body)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getVideoDetailDispatcher: any = createAsyncThunk("getVideoDetail", async (query: any) => {
  try {
    return await Services.DashboardService.GetVideoDetail(query)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getBlogDetailDispatcher: any = createAsyncThunk("getBlogDetail", async (query: any) => {
  try {
    return await Services.DashboardService.GetBlogDetail(query)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getDashboardDataDispatcher: any = createAsyncThunk("dashboard", async () => {
  try {
    return await Services.DashboardService.GetDashboardData()
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getCategoryDataDispatcher: any = createAsyncThunk("category", async () => {
  try {
    return await Services.DashboardService.GetCategoryData()
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})


export const getMediaDataDispatcher: any = createAsyncThunk("media", async (query: string) => {
  try {
    return await Services.DashboardService.GetMediaData(query)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getAddMediaDispatcher: any = createAsyncThunk("add-media", async (body: any) => {
  try {
    return await Services.DashboardService.AddMedia(body)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})

export const getDeleteMediaDispatcher: any = createAsyncThunk("getDeleteMedia", async (body: any) => {
  try {
    return await Services.DashboardService.DeleteMedia(body)
  } catch (e) {
    return { error: JSON.parse(JSON.stringify(e)) }
  }
})