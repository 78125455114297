import { useFormik } from "formik";
import { AddBlogSchema } from "../../validations";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getAddBlogDispatcher,
  getBlogDetailDispatcher,
  getBlogsDispatcher,
  getUpdateBlogDispatcher,
  getCategoryDataDispatcher,
} from "../../store/slices/dashboard-slice/dashboard-dispatchers";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import PreviewModal from "../../components/previewModal";
import { Editor } from "@tinymce/tinymce-react";
import MediaPickerModal from "../../components/MediaPickerModal";
import AddMediaForBlogsModal from '../../components/AddMediaForBlogsModal';
import Select from "react-dropdown-select";
import { getCategorySelector } from "../../store/slices/dashboard-slice/dashboard-selectors";

const AddBlogPage = () => {
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();
  const categoryData = useAppSelector(getCategorySelector);
  const { blog_id } = useParams();
  const pageSize = 10;
  const [uploadedImage, setUploadedImage] = useState<string>("");
  const [isPreviewOpened, setIsPreviewOpened] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState<any>([]);
  const [draftedData, setDraftedData] = useState({
    title: "",
    description: "",
    category: "",
    imageUrl: "",
  });
  const [isMediaOpen, setIsMediaOpen] = useState(false);
  const [isMediaForBlogsOpen, setIsMediaForBlogsOpen] = useState(false);


  const addBlogFormik = useFormik({
    initialValues: {
      title: "",
      description: "",
      category: "",
      imageUrl: "",
    },
    onSubmit: (values) => {
      if (blog_id) {
        onUpdateBlogDetails(values);
      } else {
        onAddNewBlog(values);
      }
    },
    validationSchema: AddBlogSchema,
  });

  React.useEffect(() => {
    localStorage.removeItem("blogs_drafted");
    if (blog_id) {
      getBlogDetail();
    }
    return () => {
      saveDraftedData();
    };
  }, [blog_id]);

  const getQuery = (page: number) => {
    return `page=${page}&pageSize=${pageSize}&type=admin`;
  };

  const saveDraftedData = async () => {
    const draftData = localStorage.getItem("blogs_drafted");
    if (draftData && !blog_id) {
      const res = await dispatcher(
        getAddBlogDispatcher({ ...JSON.parse(draftData), isPublish: false })
      );
      if (res?.payload?.data?.status) {
        toast.success("Blog created successfully!", {});
        dispatcher(getBlogsDispatcher(getQuery(1)));
      } else {
        toast.error(res?.payload?.error?.message, {});
      }
      localStorage.removeItem("blogs_drafted");
    }
  };

  const getBlogDetail = async () => {
    const res: any = await dispatcher(getBlogDetailDispatcher(blog_id));
    if (res?.payload?.data?.status) {
      addBlogFormik.setFieldValue(
        "title",
        res?.payload?.data?.data?.blog?.title
      );
      addBlogFormik.setFieldValue(
        "category",
        res?.payload?.data?.data?.blog?.category
      );
      addBlogFormik.setFieldValue(
        "description",
        res?.payload?.data?.data?.blog?.description
      );
      addBlogFormik.setFieldValue(
        "imageUrl",
        res?.payload?.data?.data?.blog?.imageUrl
      );
      setUploadedImage(res?.payload?.data?.data?.blog?.imageUrl);
      setSelectedCategory([{id: res?.payload?.data?.data?.blog?.category, name:res?.payload?.data?.data?.blog?.category}])
    }
  };

  const onAddNewBlog = async (values: any) => {
    const res = await dispatcher(
      getAddBlogDispatcher({ ...values, isPublish: false })
    );
    if (res?.payload?.data?.status) {
      localStorage.removeItem("blogs_drafted");
      toast.success("Blog created successfully!", {});
      navigate(-1);
    } else {
      toast.error(res?.payload?.error?.message, {});
    }
  };

  const onUpdateBlogDetails = async (values: any) => {
    const res = await dispatcher(
      getUpdateBlogDispatcher({ ...values, _id: blog_id })
    );
    if (res?.payload?.data?.status) {
      toast.success("Blog updated successfully!", {});
      navigate(-1);
    } else {
      toast.error(res?.payload?.error?.message, {});
    }
  };

  const onEditorStateChange = (text: string) => {
    // setEditorState(stats)
    addBlogFormik.setFieldValue("description", text);
  };

  const onPreviewBlog = () => {
    setIsPreviewOpened(false);
  };
  const getFieldsValues = (key: string, value: string) => {
    let OB = draftedData;
    if (!blog_id) {
      if (key === "title") {
        OB = { ...OB, title: value };
      }
      if (key === "description") {
        OB = { ...OB, description: value };
      }
      if (key === "category") {
        OB = { ...OB, category: value };
      }
      if (key === "imageUrl") {
        OB = { ...OB, imageUrl: value };
      }
      setDraftedData(OB);
      localStorage.setItem("blogs_drafted", JSON.stringify(OB));
    }
  };

  React.useEffect(() => {
    dispatcher(getCategoryDataDispatcher());
  }, [dispatcher]);

  const onSelectedImage = (url: string) => {
    setUploadedImage(url);
    setIsMediaOpen(false);
    addBlogFormik.setFieldValue("imageUrl", url);
  };

  const getCategoryOptions = () => {
    const filteredData = categoryData?.filter(v=>v)
    return filteredData?.map((v, index) => {
      return { id: v, name: v };
    });
  };

  return (
    <>
      {isPreviewOpened ? (
        <PreviewModal
          onPress={() => {
            onPreviewBlog();
          }}
          data={addBlogFormik.values}
        />
      ) : null}
      <div className="flex-col bg-[#F2F7FF] mb-12">
        <div className="flex-col mx-auto justify-center px-6 mt-12">
          <div className="p-6 bg-white rounded-3xl shadow-[0.0px_4.0px_4.0px_rgba(0,0,0,0.20)]">
            <div className="flex pb-5 justify-between">
              <h5 className="tracking-tight text-[#121212] font-nunito font-extrabold text-[26px]">
                {blog_id ? "Update Blog" : "Create Blog"}
              </h5>
              <div className="flex">
                <button
                  className="bg-gradient-to-r from-[#14ADD6] to-[#384295] p-[1px] rounded-[10px] focus:outline-none w-[200px]"
                  onClick={() => setIsPreviewOpened(true)}
                >
                  <span className="bg-gradient-to-r from-[#14ADD6] to-[#384295] font-bold block text-white hover:text-[#384295] px-4 py-[10px] bg-white rounded-[10px] hover:from-white hover:to-white font-nunito cursor-pointer">
                    Preview
                  </span>
                </button>
              </div>
            </div>
            <div className="mb-6">
              <label className="block text-[#121212] text-[22px] font-semibold font-nunito mb-2">
                Title
              </label>
              <input
                className={`border rounded-[10px] w-full py-4 px-3 text-black leading-tight ${
                  addBlogFormik.touched.title && addBlogFormik.errors?.title
                    ? "border-red-500"
                    : "border-[#000000]"
                }`}
                id="title"
                type="text"
                placeholder="Title"
                onChange={(event) => {
                  getFieldsValues("title", event?.target?.value);
                  addBlogFormik.setFieldValue("title", event?.target?.value);
                }}
                value={addBlogFormik.values?.title}
                onBlur={addBlogFormik.handleBlur}
              />
              {addBlogFormik.touched.title && addBlogFormik.errors?.title ? (
                <p className="text-red-500 text-sm italic font-normal font-nunito">
                  {addBlogFormik.errors?.title}
                </p>
              ) : null}
            </div>
            <div className="mb-6">
              <label className="block text-[#121212] text-[22px] font-semibold font-nunito mb-2">
                Description
              </label>
              <button
                  className="bg-gradient-to-r from-gray-500 to-gray-400 p-[1px] rounded-[5px] focus:outline-none w-[120px] mb-2"
                  onClick={() => setIsMediaForBlogsOpen(true)}
                >
                  <span className="bg-gradient-to-r from-gray-500 to-gray-400 font-bold block text-white  px-4 py-[5px] bg-white rounded-[10px]  font-nunito cursor-pointer">
                    Add Media
                  </span>
                </button>
              <div
                className={`w-full flex min-h-[270px] max-h-[600px] border rounded-[10px] overflow-hidden ${
                  addBlogFormik.touched.description &&
                  addBlogFormik.errors?.description
                    ? "border-red-500"
                    : "border-[#000000]"
                }`}
              >
                <Editor
                  apiKey="hlkqs6mv7kyf2ee5dzqy0p10qg5pg0nz8w42fw26lrojgz3c"
                  onEditorChange={(event) => {
                    getFieldsValues("description", event);
                    onEditorStateChange(event);
                  }}
                  init={{
                    width: "100%",
                    plugins:
                      "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss",
                    toolbar:
                      "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                    tinycomments_mode: "embedded",
                    tinycomments_author: "Author name",
                    mergetags_list: [
                      { value: "First.Name", title: "First Name" },
                      { value: "Email", title: "Email" },
                    ],
                    ai_request: (request: any, respondWith: any) =>
                      respondWith.string(() =>
                        Promise.reject("See docs to implement AI Assistant")
                      ),
                  }}
                  value={addBlogFormik.values?.description}
                  // initialValue="Welcome to TinyMCE!"
                />
              </div>
              {addBlogFormik.touched.description &&
              addBlogFormik.errors?.description ? (
                <p className="text-red-500 text-sm italic font-normal font-nunito">
                  {addBlogFormik.errors?.description}
                </p>
              ) : null}
            </div>
            <div className="mb-6">
              <label className="block text-[#121212] text-[22px] font-semibold font-nunito mb-2">
                Categories
              </label>
              <Select
                options={getCategoryOptions()}
                labelField="name"
                placeholder="Select Category"
                addPlaceholder="Add Category"
                valueField="id"
                values={selectedCategory}
                clearable
                searchable
                create
                separator
                onCreateNew={(v) => {console.log('vvvv',v)}}
                dropdownHandle
                keepSelectedInList
                style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderRadius: 10,
                  borderColor: "black",
                  fontSize: 16,
                  paddingLeft: 12,
                  paddingRight: 12,
                  fontFamily: "nunito",
                  fontWeight:'600'
                }}
                onChange={(v:any) => {
                  setSelectedCategory(v)
                  addBlogFormik.setFieldValue( "category",v?.[0]?.name || "");
                }}
              />
              {addBlogFormik.touched?.category &&
              addBlogFormik.errors?.category ? (
                <p className="text-red-500 text-sm italic font-normal font-nunito">
                  {addBlogFormik.errors?.category}
                </p>
              ) : null}
            </div>
            <div className="mb-6">
              <label className="block text-[#121212] text-[22px] font-semibold font-nunito mb-2">
                Featured Image
              </label>
              <label
                onClick={() => {
                  setIsMediaOpen(true);
                }}
                className={`border rounded-[10px] w-full bg-white text-center min-h-[270px] py-4 px-4 flex flex-col items-center justify-center cursor-pointer mx-auto font-nunito ${
                  addBlogFormik.touched.imageUrl &&
                  addBlogFormik.errors?.imageUrl
                    ? "border-red-500"
                    : "border-[#000000]"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 mb-6 fill-gray-400"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M22 13a1 1 0 0 0-1 1v4.213A2.79 2.79 0 0 1 18.213 21H5.787A2.79 2.79 0 0 1 3 18.213V14a1 1 0 0 0-2 0v4.213A4.792 4.792 0 0 0 5.787 23h12.426A4.792 4.792 0 0 0 23 18.213V14a1 1 0 0 0-1-1Z"
                    data-original="#000000"
                  />
                  <path
                    d="M6.707 8.707 11 4.414V17a1 1 0 0 0 2 0V4.414l4.293 4.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 0 0 1.414 1.414Z"
                    data-original="#000000"
                  />
                </svg>
                <p className="text-[#00000059] text-[22px] font-nunito font-bold">
                  <span className="text-[#007bff]">Choose file</span> to upload
                  image
                </p>
                <p className="text-xs text-[#00000059] mt-2 font-nunito">
                  PNG, JPG are Allowed.
                </p>
                {uploadedImage ? (
                  <img
                    className="object-cover h-auto w-1/3"
                    src={uploadedImage}
                  />
                ) : null}
              </label>
              {addBlogFormik.touched.imageUrl &&
              addBlogFormik.errors?.imageUrl ? (
                <p className="text-red-500 text-sm italic font-normal font-nunito">
                  {addBlogFormik.errors?.imageUrl}
                </p>
              ) : null}
            </div>
            <div className="flex items-center justify-center w-full">
              <div className="flex">
                <button
                  onClick={() => addBlogFormik.handleSubmit()}
                  className="bg-gradient-to-r from-[#14ADD6] to-[#384295] p-[1px] rounded-[10px] focus:outline-none w-[200px]"
                >
                  <span className="bg-gradient-to-r from-[#14ADD6] to-[#384295] font-bold block text-white hover:text-[#384295] px-4 py-[10px] bg-white rounded-[10px] hover:from-white hover:to-white font-nunito cursor-pointer">
                    Upload
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {isMediaOpen ? (
          <MediaPickerModal
            onClicked={() => {
              setIsMediaOpen(false);
            }}
            onSelected={(url: string) => {
              onSelectedImage(url);
            }}
          />
        ) : null}
         {isMediaForBlogsOpen ? (
          <AddMediaForBlogsModal
            onClicked={() => {
              setIsMediaForBlogsOpen(false);
            }}
            onSelected={(url: string) => {
              onSelectedImage(url);
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default AddBlogPage;
