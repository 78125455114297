import React from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getAddMediaDispatcher,
  getDeleteMediaDispatcher,
  getMediaDataDispatcher,
  setLoadingDispatcher,
} from "../../store/slices/dashboard-slice/dashboard-dispatchers";
import {
  getMediaSelector,
  getMediaTotalPageSelector,
} from "../../store/slices/dashboard-slice/dashboard-selectors";
import BottomPagination from "../../components/Pagination";
import MediaCardComponent from "../../components/MediaCardComponent";
import AWS from "aws-sdk";
import MediaModal from "../../components/MediaModal";
import { toast } from "react-toastify";

let timerOne: any = null;

export default function MediaPage() {
  const totalPage = useAppSelector(getMediaTotalPageSelector);
  const myMedia = useAppSelector(getMediaSelector);
  const dispatcher = useAppDispatch();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedMedia, setSelectedMedia] = React.useState<any>({});
  const [searchText, setSearchText] = React.useState("");

  const pageSize = 29;

  const getQuery = (page: number) => {
    return `page=${page}&pageSize=${pageSize}`;
  };

  React.useEffect(() => {
    dispatcher(getMediaDataDispatcher(getQuery(1)));
  }, [dispatcher]);

  const onPaginationChanged = async (page: number) => {
    await dispatcher(getMediaDataDispatcher(getQuery(page)));
    setCurrentPage(page);
  };

  const onFileChange = (event: any) => {
    console.log(event?.target?.files);
    const filesArray = Array.from(event.target.files);
    uploadFile(filesArray);
  };

  const uploadFile = async (files: any) => {
    dispatcher(setLoadingDispatcher(true));
    const s3 = new AWS.S3({
      accessKeyId: "AKIA4MTWLEQT3IYGRJQ7",
      secretAccessKey: "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",
    });
    let uploadFiles: any = [];
    files &&
      files?.map((file: any) => {
        const fileKay = file?.name?.replaceAll(/\s/g, "_");
        const params = {
          Bucket: "a2z-bucket",
          Key: fileKay,
          Body: file,
        };
        uploadFiles.push(s3.upload(params).promise());
      });
    Promise.all(uploadFiles)
      .then((responses) => {
        let urls: any = [];
        urls = responses?.map((res) => {
          return { title: res?.key, url: res?.Location };
        });
        addMediaFile(urls);
      })
      .catch((error) => {
        dispatcher(setLoadingDispatcher(false));
      });
  };

  const addMediaFile = async (urls: any) => {
    let uploadNewFiles: any = [];
    urls?.map((url: any) => {
      uploadNewFiles.push(dispatcher(getAddMediaDispatcher(url)));
    });
    Promise.all(uploadNewFiles)
      .then((responses) => {
        dispatcher(setLoadingDispatcher(false));
        dispatcher(getMediaDataDispatcher(getQuery(1)));
      })
      .catch((error) => {
        dispatcher(setLoadingDispatcher(false));
      });
  };

  const onDeleteMedia = async (type: string) => {
    setIsModalOpen(false);
    if (type === "delete") {
      const res = await dispatcher(
        getDeleteMediaDispatcher({ id: selectedMedia?._id })
      );
      if (res?.payload?.data?.status) {
        toast.success("Media deleted successfully", {});
        dispatcher(getMediaDataDispatcher(getQuery(1)));
      } else {
        toast.error(res?.payload?.error?.message, {});
      }
    } else {
      setIsModalOpen(false);
      setSelectedMedia({});
    }
  };

  const onModalOpen = (media: any) => {
    setIsModalOpen(true);
    setSelectedMedia(media);
  };

  const handleSearch = (val: string) => {
    setSearchText(val);
    clearTimeout(timerOne);
    timerOne = setTimeout(() => {
      callAPI(val);
    }, 1000);
  };

  const callAPI = (search: string) => {
    setCurrentPage(1);
    let query = getQuery(1);
    if (search) {
      query = query + "&search=" + search;
    }
    dispatcher(getMediaDataDispatcher(query));
  };
  const handleClearFilter = () => {
    setSearchText("");
    setCurrentPage(1);
    let query = getQuery(1);
    dispatcher(getMediaDataDispatcher(query));
  };

  return (
    <>
      <div className="flex-col w-full bg-[#F2F7FF]">
        <div className="flex-col mx-auto justify-center px-6 mt-10">
          <div className="w-full relative overflow-x-auto py-6 mb-8">
            <div className="flex mb-6 flex-wrap gap-5">
              <div className="relative w-[280px]">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                  <span className="sr-only">Search icon</span>
                </div>
                <input
                  type="text"
                  value={searchText}
                  onChange={(val) => handleSearch(val?.target?.value)}
                  id="search-navbar"
                  className="w-full h-[45px] block py-2 px-4 ps-10 text-md text-gray-900 border border-[#C0C0C0] rounded-[5px] focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Search here"
                />
              </div>
              <div className="flex w-[120px]">
                <button
                  onClick={handleClearFilter}
                  className="bg-[#FF9119] text-white font-medium py-2 px-4 rounded-[5px] transparent border-white text-[14px] font-poppins h-[45px]"
                >
                  Clear filter
                </button>
              </div>
            </div>
            <div className="flex flex-wrap justify-center mt-2 gap-6">
              {[{ upload: true }, ...myMedia]?.map(
                (item: any, index: number) => (
                  <MediaCardComponent
                    key={item?._id + index}
                    onFileChange={onFileChange}
                    isUpload={item?.upload}
                    onModalOpen={(v: any) => {
                      onModalOpen(v);
                    }}
                    data={item}
                  />
                )
              )}
            </div>
            <div className="flex mt-6 justify-end">
              <BottomPagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalPage}
                pageSize={pageSize}
                onPageChange={(page: any) => {
                  onPaginationChanged(page);
                }}
              />
            </div>
          </div>
        </div>
        {isModalOpen ? (
          <MediaModal onPress={(v) => onDeleteMedia(v)} data={selectedMedia} />
        ) : null}
      </div>
    </>
  );
}
