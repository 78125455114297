import { createDraftSafeSelector } from "@reduxjs/toolkit"
import { type RootState } from "../../store"

const dashboardSelector = (state: RootState) => state.dashboardReducer

export const getSubscriberSelector = createDraftSafeSelector(
  [ dashboardSelector ],
  items => {
    return items.mySubscribers
  },
)

export const getBlogsSelector = createDraftSafeSelector(
  [ dashboardSelector ],
  items => {
    return items.myBlogs
  },
)

export const getVideosSelector = createDraftSafeSelector(
  [ dashboardSelector ],
  items => {
    return items.myVideos
  },
)

export const getDashboardDataSelector = createDraftSafeSelector(
  [ dashboardSelector ],
  items => {
    return items.dashboardData
  },
)

export const getLoadingSelector = createDraftSafeSelector(
  [ dashboardSelector ],
  items => {
    return items.loading
  },
)

export const getBlogTotalPageSelector = createDraftSafeSelector(
  [ dashboardSelector ],
  items => {
    return items.totalBlogPage
  },
)

export const getVideoTotalPageSelector = createDraftSafeSelector(
  [ dashboardSelector ],
  items => {
    return items.totalVideoPage
  },
)

export const getSubscriberTotalPageSelector = createDraftSafeSelector(
  [ dashboardSelector ],
  items => {
    return items.totalSubscriberPage
  },
)

export const getMediaTotalPageSelector = createDraftSafeSelector(
  [ dashboardSelector ],
  items => {
    return items.totalMediaPage
  },
)

export const getCategorySelector = createDraftSafeSelector(
  [ dashboardSelector ],
  items => {
    return items.categories
  },
)

export const getMediaSelector = createDraftSafeSelector(
  [ dashboardSelector ],
  items => {
    return items.myMedia
  },
)
