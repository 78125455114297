import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from './pages/landing-page';
import AdminLayout from './layouts/admin';
import DashboardPage from './pages/dashboard';
import BlogPage from './pages/blogs';
import SubscriberPage from './pages/subscriber';
import AddBlogPage from './pages/blogs/addBlog';
import AddVideoPage from './pages/videos/addVideo';
import VideosPage from './pages/videos';
import PrivateRoute from "./PrivateRoute"
import MediaPage from './pages/media';

function App () {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={ <PrivateRoute /> }>
          <Route path="/admin/" element={ <AdminLayout /> }>
            <Route path="blogs/add-blog" element={ <AddBlogPage /> } />
            <Route path="blogs/:blog_id/get-blog-detail" element={ <AddBlogPage /> } />
            <Route path="videos/:video_id/get-video-detail" element={ <AddVideoPage /> } />
            <Route path="videos/add-video" element={ <AddVideoPage /> } />
            <Route path="media" element={ <MediaPage /> } />
            <Route path="blogs" element={ <BlogPage /> } />
            <Route path="videos" element={ <VideosPage /> } />
            <Route path="subscriber" element={ <SubscriberPage /> } />
            <Route path="dashboard" element={ <DashboardPage /> } />
            <Route path="" element={ <DashboardPage /> } />
          </Route>
        </Route>
        <Route path="" element={ <LandingPage /> } />
      </Routes >
    </BrowserRouter >
  );
}

export default App;
