import moment from "moment";
export default function AdminNavBar () {

  return (
    <>
      <nav className="w-full h-16 my-6">
        <div className="mx-auto px-4">
          <div className="relative flex items-center justify-between">
            <div className="flex-col flex-shrink-0 items-center">
              <div className="flex">
                <h3 className=" font-nunito font-extrabold text-[20px] text-black mr-2">Welcome, </h3>
                <img className="object-cover h-auto w-[30px]" src={ require('../assets/images/Happy.png') } />
              </div>
              <p className="text-[#262626] text-[12px] font-nunito font-medium pt-2">Today is { moment().format('dddd') }, { Number(moment().format('DD')) }th { moment().format('MMM') } { moment().format('YYYY') }.</p>
            </div>
            <div className="absolute right-[60px] flex items-center">
              <div className="relative flex justify-center items-center">
                <div>
                  <img className="h-[45px] w-[45px] rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                </div>
                <div className="pl-4">
                  <h3 className=" font-nunito font-medium text-[14px] text-[#121212]">Admin</h3>
                  <p className="text-[#515151] text-[12px] font-nunito font-medium">A2Z Tax Corp</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
