import axios from "axios"
import { getAxiosResposeInterceptors } from '../interceptors'

const AxiosService = axios.create({
  baseURL: 'https://apiserver.a2ztaxcorp.com/',
});

AxiosService.interceptors.request.use(
  async function (request) {
    const isLoggedIn = localStorage.getItem("a2z-crs-token");
    if (isLoggedIn) {
      request.headers[ 'Authorization' ] = `Bearer ${isLoggedIn}`
    }
    return request
  },
  async function (error) { return await Promise.reject(error) }
)

AxiosService.interceptors.response.use(response => {
  return response;
}, (error) => {
  if (error?.response && error?.response?.status === 401) {
    localStorage.clear();
    setTimeout(() => {
      window.location.href = "/";
    }, 500);
    return Promise.reject('Unauthorized');
  }
  // Handle other errors here
  return Promise.reject(error);
})

export default AxiosService
