import moment from "moment";
import parse from 'html-react-parser';

interface previewModalProps {
	onPress: () => void
	data: any;
}
export default function previewModal (props: previewModalProps) {
	return (
		<>
			<div
				className="flex fixed inset-0 z-40 backdrop-brightness-50 outline-none focus:outline-none">
				<div className="flex relative my-6 mx-auto max-w-4xl  ">
					<div className="p-8 border-0 rounded-xl shadow-md relative flex flex-col w-full bg-white outline-none focus:outline-none overflow-scroll">
						<div className="w-full">
							<div className="flex w-full justify-center border-1 border-[#D3D3D3] rounded-[22px]">
								<img
									src={ props?.data?.imageUrl }
									alt="Flowbite Logo"
									className=" rounded-2xl scale-100 aspect-[6/4] w-full "
								/>
							</div>
							<div className="py-8 leading-loose">
								<p className="font-poppins text-[16px] font-bold leading-loose text-black">
									{ props?.data?.title }
								</p>
								<div className="flex flex-wrap py-5 leading-tight gap-4">
									<button className="bg-transparent text-[#171717] font-poppins text-[12px] font-normal md:py-[8px] md:px-4 p-2 border border-[#E1E0E1] border-solid rounded-md">
										By: Admin
									</button>
									<button className="bg-transparent text-[#171717] font-poppins text-[12px] font-normal md:py-[8px] md:px-4  p-2 border border-[#E1E0E1] border-solid rounded-md">
										{ moment().format("MMMM DD, YYYY") }
									</button>
									<button className="bg-transparent text-[#171717] font-poppins text-[12px] font-normal md:py-[8px] md:px-4 p-2  border border-[#E1E0E1] border-solid rounded-md">
										Categories: <span className="font-medium font-poppins text-[13px] text-[#171717]">{ props?.data?.category }</span>
									</button>
									<button className="bg-transparent text-[#171717] font-poppins text-[12px] font-normal md:py-[8px] md:px-4 p-2 border border-[#E1E0E1] border-solid rounded-md">
										4 Min Read
									</button>
								</div>
								<div className=" text-black leading-loose">
									{ props?.data?.description ? parse(props?.data?.description) : "" }
								</div>
							</div>
						</div>
						<div className="flex items-center justify-center w-full">
							<div className="flex">
								<button onClick={ () => props?.onPress?.() } className="bg-gradient-to-r from-[#14ADD6] to-[#384295] p-[1px] rounded-[10px] focus:outline-none w-[200px]">
									<span className="bg-gradient-to-r from-[#14ADD6] to-[#384295] font-bold block text-white hover:text-[#384295] px-4 py-[10px] bg-white rounded-[10px] hover:from-white hover:to-white font-nunito cursor-pointer">Ok</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
