import React from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getMediaSelector } from "../store/slices/dashboard-slice/dashboard-selectors";
import { getMediaDataDispatcher } from "../store/slices/dashboard-slice/dashboard-dispatchers";
import useDebounce from "../components/useDebounce";

interface MediaPickerModalProps {
  onClicked: () => void;
  onSelected: (url: string) => void;
}

export default function MediaPickerModal(props: MediaPickerModalProps) {
  const [search, setSearch] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [mediaData, setMediaData] = React.useState<any>([]);

  const myMedia = useAppSelector(getMediaSelector);
  const dispatcher = useAppDispatch();

  const getMedia = () => {
    setMediaData(myMedia);
  };
  React.useEffect(() => {
    getMedia();
  }, [myMedia]);

  const getQuery = (page: number) => {
    return `page=${page}&pageSize=${15000}`;
  };
  
  React.useEffect(() => {
    dispatcher(getMediaDataDispatcher(getQuery(1)));
  }, [dispatcher]);

  const SearchMedia = () => {
    const filtered = myMedia?.filter((v) =>
      v?.title?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())
    );
    setMediaData(filtered);
  };

  useDebounce(
    () => {
      setSearch(searchText);
    },
    [searchText],
    800
  );

  const handleSearch = (v: any) => {
    setSearchText(v);
  };

  React.useEffect(() => {
    SearchMedia();
  }, [search]);

  return (
    <>
      <div className="flex fixed inset-0 z-50 backdrop-brightness-50 outline-none focus:outline-none items-center">
        <div className="flex relative mx-auto w-2/3 justify-center overflow-auto h-[96%]">
          <div className="py-4 bg-white border-0 rounded-xl shadow-md relative flex flex-col w-full  outline-none focus:outline-none  items-center h-full">
            <div className=" flex w-full justify-between pb-5 px-6">
              <p className="font-poppins text-[18px] font-bold leading-loose text-black text-center">
                Select Media Files
              </p>
              <input
                type="text"
                value={searchText}
                onChange={(val) => handleSearch(val?.target?.value)}
                id="search-navbar"
                className="w-1/3 h-[45px] block py-2 px-4 ps-10 text-md text-gray-900 border border-[#C0C0C0] rounded-[5px] focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search here"
              />
            </div>
            <div className="flex flex-wrap justify-center mt-4 gap-6 overflow-scroll w-full">
              {mediaData?.map((item: any, index: number) => {
                if (
                  item?.url?.toLocaleLowerCase()?.includes("png") ||
                  item?.url?.toLocaleLowerCase()?.includes("jpg") ||
                  item?.url?.toLocaleLowerCase()?.includes("jpeg") ||
                  item?.url?.toLocaleLowerCase()?.includes("tiff") ||
                  item?.url?.toLocaleLowerCase()?.includes("gif")
                ) {
                  return (
                    <div
                      key={item?.url + index + item?.title}
                      onClick={() => {
                        props?.onSelected?.(item?.url);
                      }}
                      className="relative w-full md:w-[30%] rounded-lg border border-[#000000] shadow-lg overflow-hidden bg-green-200"
                    >
                      <img
                        src={item?.url}
                        className="h-[200px] w-full rounded-lg scale-100 aspect-[5/4] cursor-pointer object-cover"
                        alt={item?.title}
                      />
                      <div className="absolute bottom-0 left-0 right-0 px-4 py-3 bg-gray-800 opacity-70">
                        <h3 className="text-center text-white text-[14px]">
                          {item?.title}
                        </h3>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div className="flex items-center justify-center mt-4">
              <div className="flex">
                <button
                  onClick={() => {
                    props?.onClicked?.();
                  }}
                  className="bg-gradient-to-r from-[#14ADD6] to-[#384295] p-[1px] rounded-[10px] focus:outline-none w-[200px]"
                >
                  <span className="bg-gradient-to-r from-[#14ADD6] to-[#384295] font-bold block text-white hover:text-[#384295] px-4 py-[10px] bg-white rounded-[10px] hover:from-white hover:to-white font-nunito cursor-pointer">
                    Cancel
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
