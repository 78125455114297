import { createDraftSafeSelector } from "@reduxjs/toolkit"
import { type RootState } from "../../store"

const loginSelector = (state: RootState) => state.loginReducer

/**
 *  @returns { string }
 */
export const getLoadingSelector = createDraftSafeSelector(
  [ loginSelector ],
  items => {
    return items
  },
)
