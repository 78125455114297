import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getLoadingSelector } from "../../store/slices/login-slice/login-selectors";
import { getLoginDispatcher } from "../../store/slices/login-slice/login-dispatchers";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useFormik } from "formik"
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { SignInSchema } from "../../validations";
import { toast } from "react-toastify";

export default function LandingPage () {
  const navigate = useNavigate();
  const loginData = useAppSelector(getLoadingSelector)
  const dispatcher = useAppDispatch()
  const [ isPasswordVisible, setIsPasswordVisible ] = React.useState(false)

  const signInFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      onLogin(values)
    },
    validationSchema: SignInSchema,
  })

  const onLogin = async (values: any) => {
    try {
      const res = await dispatcher(getLoginDispatcher(values))
      if (res?.payload?.data?.status) {
        navigate('/admin/dashboard', { replace: true })
      } else {
        toast.error("Invalid credentials. Please try again.", {});
      }
    } catch (error) { }
  }

  return (
    <>
      <div className="md:flex h-screen">
        <div className="mx-auto h-auto flex w-1/2 bg-white justify-center items-center">
          <div className="min-w-[450px]">
            <form className="bg-white rounded px-8 pt-6 pb-8">
              <div>
                <img className="object-cover h-auto w-50" src={ require('../../assets/images/logo.png') } />
              </div>
              <div className="mt-10 mb-12">
                <p className=" text-[#383838] text-[14px] font-normal font-nunito">Welcome Back!!</p>
                <h2 className="text-[#272525] text-[28px] font-extrabold font-nunito">Please Sign In</h2>
              </div>
              <div className="mb-6">
                <label className="block text-[#121212] text-[14px] font-normal font-nunito mb-2">
                  Email Address
                </label>
                <input className="border border-[#D0D0D0] rounded-[10px] w-full py-3 px-3 text-gray-700 leading-tight"
                  id="email"
                  type="text"
                  placeholder="Enter email address"
                  onChange={ signInFormik.handleChange }
                  value={ signInFormik.values?.email }
                  onBlur={ signInFormik.handleBlur }
                />
                { signInFormik.touched.email && signInFormik.errors?.email ? <p className="text-red-500 text-xs italic font-normal font-nunito">{ signInFormik.errors?.email }</p> : null }
              </div>
              <div className="mb-6">
                <label className="block text-[#121212] text-[14px] font-normal font-nunito mb-2">
                  Password
                </label>
                <div className="relative">
                  <input className="border border-[#D0D0D0] rounded-[10px] w-full py-3 px-3 text-gray-700 leading-tight placeholder:translate-y-[3px]"
                    id="password"
                    type={ isPasswordVisible ? "text" : "password" }
                    placeholder="**********"
                    onChange={ signInFormik.handleChange }
                    value={ signInFormik.values?.password }
                    onBlur={ signInFormik.handleBlur }
                  />
                  <div onClick={ () => { setIsPasswordVisible(!isPasswordVisible) } } className="absolute top-1/2 right-3 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer">
                    { isPasswordVisible ? <BsEye size={ 18 } color="#777777" /> : <BsEyeSlash size={ 18 } color="#777777" /> }
                  </div>
                </div>
                { signInFormik.touched.password && signInFormik.errors?.password ? <p className="text-red-500 text-xs italic font-normal font-nunito">{ signInFormik.errors?.password }</p> : null }
              </div>
              <div className="flex items-center justify-between">
                <button type="button" onClick={ () => signInFormik.handleSubmit() } className="bg-gradient-to-r from-[#14ADD6] to-[#384295] p-[1px] rounded-[10px] focus:outline-none w-full">
                  <span className="bg-gradient-to-r from-[#14ADD6] to-[#384295] font-bold block text-white hover:text-[#384295] px-4 py-[10px] bg-white rounded-[10px] hover:from-white hover:to-white font-nunito cursor-pointer">Sign In</span>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="mx-auto h-auto flex md:w-1/2">
          <img className="h-screen w-screen object-cover" src={ require('../../assets/images/LandingCard.png') } />
        </div>
      </div>
    </>
  );
}
