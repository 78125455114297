import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { getLoginDispatcher } from "./login-dispatchers";

const initialState: LoginDataType = {
  loading: false,
  success: false
};

export const loginSlice = createSlice({
  name: "LoginReducer",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoginDispatcher.pending, (state: any) => {
        state.loading = true;
        state.success = false
      })
      .addCase(getLoginDispatcher.fulfilled, (state: any, action: any) => {
        if (action.payload?.data?.status) {
          localStorage.setItem("a2z-crs-token", action.payload?.data?.data?.userToken)
          state.success = true
        } else {
          state.success = false
        }
        state.loading = false;
      })
      .addCase(getLoginDispatcher.rejected, (state: any) => {
        state.loading = false;
      })
  },
});
export const { setLoading } = loginSlice.actions;

export default loginSlice.reducer;
