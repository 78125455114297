import * as Yup from "yup";

export const SignInSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required")
});

export const AddBlogSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  category: Yup.string().required("Category is required"),
  imageUrl: Yup.string().required("Featured image is required")
});

export const AddVideoSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  thumbnailUrl: Yup.string().required("Thumbnail url is required"),
  videoUrl: Yup.string().required("Video url is required"),
});
