export const Endpoints = {
  SignIn: "/users/userLogin",
  Subscribers: "/subscribers/getAllSubscribers?",
  AddSubscribers:"/subscribers/createSubscriber",
  DeleteSubscribers:"/subscribers/deleteSubscriber",
  Blogs: "/blogs/getBlogs?",
  AddBlog: "/blogs/newBlog",
  GetBlogDetail: "/blogs/getBlog/",
  UpdateBlog: "/blogs/updateBlog",
  DeleteBlog: "/blogs/deleteBlog",
  Videos: "/vlogs/getVlog?",
  AddVideo: "/vlogs/addVlog",
  UpdateVideo: "/vlogs/updateVlog",
  GetVideoDetail: "/vlogs/getVlog/",
  DeleteVideo: "/vlogs/deleteVlog",
  DashboardCount: "/users/getDashboard",
  categories: "/blogs/getBlogCategory?type=admin",
  medias: "/media/getMedia?",
  AddMedia: "/media/addMedia",
  DeleteMedia:"media/deleteMedia",
}
