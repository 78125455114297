import React from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getMediaSelector } from "../store/slices/dashboard-slice/dashboard-selectors";
import { getMediaDataDispatcher } from "../store/slices/dashboard-slice/dashboard-dispatchers";
import useDebounce from "./useDebounce";
import ReactPlayer from "react-player";
import { pdfjs, Document, Page } from "react-pdf";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";

interface MediaPickerModalProps {
  onClicked: () => void;
  onSelected: (url: string) => void;
}

export default function MediaPickerModal(props: MediaPickerModalProps) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [search, setSearch] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [mediaData, setMediaData] = React.useState<any>([]);

  const myMedia = useAppSelector(getMediaSelector);
  const dispatcher = useAppDispatch();


  const getMedia = () => {
    setMediaData(myMedia);
  };
  React.useEffect(() => {
    getMedia();
  }, [myMedia]);

  const getQuery = (page: number) => {
    return `page=${page}&pageSize=${15000}`;
  };
  
  React.useEffect(() => {
    dispatcher(getMediaDataDispatcher(getQuery(1)));
  }, [dispatcher]);

  const SearchMedia = () => {
    const filtered = myMedia?.filter((v) =>
      v?.title?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())
    );
    setMediaData(filtered);
  };

  useDebounce(
    () => {
      setSearch(searchText);
    },
    [searchText],
    800
  );

  const handleSearch = (v: any) => {
    setSearchText(v);
  };

  React.useEffect(() => {
    SearchMedia();
  }, [search]);

 console.log(' mediaData', mediaData)

  return (
    <>
      <div className="flex fixed inset-0 z-50 backdrop-brightness-50 outline-none focus:outline-none items-center">
        <div className="flex relative mx-auto w-2/3 justify-center overflow-auto h-[96%]">
          <div className="py-4 bg-white border-0 rounded-xl shadow-md relative flex flex-col w-full  outline-none focus:outline-none  items-center h-full">
            <div className=" flex w-full justify-between pb-5 px-10">
              <p className="font-poppins text-[18px] font-bold leading-loose text-black text-center">
                Select Media Files
              </p>
              <input
                type="text"
                value={searchText}
                onChange={(val) => handleSearch(val?.target?.value)}
                id="search-navbar"
                className="w-1/3 h-[45px] block py-2 px-4 ps-10 text-md text-gray-900 border border-[#C0C0C0] rounded-[5px] focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search here"
              />
            </div>
            <div className="flex flex-wrap justify-center mt-4 gap-6 overflow-scroll w-full ">
              {mediaData?.map((item: any, index: number) => {
                if (String(item?.url)?.toLocaleLowerCase()?.includes(".pdf")) {
                  return (
                    <div
                      className="relative w-full md:w-[30%] rounded-lg border border-[#000000] shadow-lg overflow-hidden cursor-pointer"
                      onClick={() => {
                        copy(item?.url);
                        toast.success("Copied", {});
                      }}
                    >
                      <Document
                        file={item?.url}
                        className=" justify-center items-center flex"
                      >
                        <Page height={200} pageNumber={1} />
                      </Document>
                      <div className="absolute bottom-0 left-0 right-0 px-4 py-3 bg-gray-800 opacity-70 ">
                        <h3 className="text-center text-white text-[14px]">
                          {item?.title}
                        </h3>
                      </div>
                    </div>
                  );
                }
                if (String(item?.url)?.toLocaleLowerCase()?.includes(".mp4")) {
                  return (
                    <>
                      <div className="relative w-full md:w-[30%] rounded-lg border border-[#000000] shadow-lg overflow-hidden cursor-pointer"  onClick={() => {
                        copy(item?.url);
                        toast.success("Copied", {});

                      }}>
                        <ReactPlayer
                          className="react-player"
                          url={item?.url}
                          width="100%"
                          height={200}
                          playing={false}
                        />
                        <div className="absolute bottom-0 left-0 right-0 px-4 py-3 bg-gray-800 opacity-70 ">
                          <h3 className="text-center text-white text-[14px]">
                            {item?.title}
                          </h3>
                        </div>
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="101"
                            height="101"
                            viewBox="0 0 101 101"
                          >
                            <defs>
                              <filter
                                id="Icon_ionic-ios-play-circle"
                                x="0"
                                y="0"
                                width="101"
                                height="101"
                                filterUnits="userSpaceOnUse"
                              >
                                <feOffset dy="3" />
                                <feGaussianBlur
                                  stdDeviation="8.5"
                                  result="blur"
                                />
                                <feFlood flood-opacity="0.161" />
                                <feComposite operator="in" in2="blur" />
                                <feComposite in="SourceGraphic" />
                              </filter>
                            </defs>
                            <g
                              transform="matrix(1, 0, 0, 1, 0, 0)"
                              filter="url(#Icon_ionic-ios-play-circle)"
                            >
                              <path
                                id="Icon_ionic-ios-play-circle-2"
                                data-name="Icon ionic-ios-play-circle"
                                d="M28.375,3.375a25,25,0,1,0,25,25A25,25,0,0,0,28.375,3.375ZM38.447,28.844,21.957,38.82a.535.535,0,0,1-.805-.469V18.4a.533.533,0,0,1,.805-.469l16.49,9.976A.551.551,0,0,1,38.447,28.844Z"
                                transform="translate(22.13 19.13)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </>
                  );
                }
                return (
                  <>
                    <div className="relative w-full md:w-[30%] rounded-lg border border-[#000000] shadow-lg overflow-hidden"  onClick={() => {
                        copy(item?.url);
                        toast.success("Copied", {});

                      }}>
                      <img
                        src={item?.url}
                        className="h-[200px] w-full rounded-lg scale-100 aspect-[5/4] cursor-pointer object-cover"
                        alt={item?.title}
                      />
                      <div className="absolute bottom-0 left-0 right-0 px-4 py-3 bg-gray-800 opacity-70 ">
                        <h3 className="text-center text-white text-[14px]">
                          {item?.title}
                        </h3>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="flex items-center justify-center mt-4">
              <div className="flex">
                <button
                  onClick={() => {
                    props?.onClicked?.();
                  }}
                  className="bg-gradient-to-r from-[#14ADD6] to-[#384295] p-[1px] rounded-[10px] focus:outline-none w-[200px]"
                >
                  <span className="bg-gradient-to-r from-[#14ADD6] to-[#384295] font-bold block text-white hover:text-[#384295] px-4 py-[10px] bg-white rounded-[10px] hover:from-white hover:to-white font-nunito cursor-pointer">
                    Cancel
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
