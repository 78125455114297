import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import ReactPlayer from "react-player";

const MediaCardComponent = ({
  isUpload,
  data,
  onFileChange,
  onModalOpen,
}: any) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  if (isUpload) {
    return (
      <div className="relative w-full md:w-[18%] rounded-lg shadow-lg border border-[#000000]">
        <label className=" rounded-lg w-full bg-white text-center h-[200px] py-4 px-4 flex flex-col items-center justify-center cursor-pointer mx-auto font-nunito ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 mb-6 fill-gray-400"
            viewBox="0 0 24 24"
          >
            <path
              d="M22 13a1 1 0 0 0-1 1v4.213A2.79 2.79 0 0 1 18.213 21H5.787A2.79 2.79 0 0 1 3 18.213V14a1 1 0 0 0-2 0v4.213A4.792 4.792 0 0 0 5.787 23h12.426A4.792 4.792 0 0 0 23 18.213V14a1 1 0 0 0-1-1Z"
              data-original="#000000"
            />
            <path
              d="M6.707 8.707 11 4.414V17a1 1 0 0 0 2 0V4.414l4.293 4.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 0 0 1.414 1.414Z"
              data-original="#000000"
            />
          </svg>
          <p className="text-[#00000059] text-[14px] font-nunito font-semibold">
            <span className="text-[#007bff]">Choose file to upload</span>
          </p>
          <input
            type="file"
            id="uploadFile1"
            multiple
            className="hidden"
            onChange={(e) => {
              onFileChange(e);
            }}
          />
          <p className="text-xs text-[#00000059] mt-2 font-nunito">
            pdf, jpg, png, mp4 etc
          </p>
        </label>
      </div>
    );
  }
  if (String(data?.url)?.toLocaleLowerCase()?.includes(".pdf")) {
    return (
      <>
        <div
          className="relative w-full md:w-[18%] rounded-lg border border-[#000000] shadow-lg overflow-hidden"
          onClick={() => onModalOpen(data)}
        >
          <Document
            file={data?.url}
            className=" justify-center items-center flex"
          >
            <Page height={200} pageNumber={1} />
          </Document>
          <div className="absolute bottom-0 left-0 right-0 px-4 py-3 bg-gray-800 opacity-70 ">
            <h3 className="text-center text-white text-[14px]">
              {data?.title}
            </h3>
          </div>
        </div>
      </>
    );
  }
  if (String(data?.url)?.toLocaleLowerCase()?.includes(".mp4")) {
    return (
      <>
        <div
          className="relative w-full md:w-[18%] rounded-lg border border-[#000000] shadow-lg overflow-hidden"
          onClick={() => onModalOpen(data)}
        >
          <ReactPlayer
            className="react-player"
            url={data?.url}
            width="100%"
            height={200}
            playing={false}
          />
          <div className="absolute bottom-0 left-0 right-0 px-4 py-3 bg-gray-800 opacity-70 ">
            <h3 className="text-center text-white text-[14px]">
              {data?.title}
            </h3>
          </div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="101"
              height="101"
              viewBox="0 0 101 101"
            >
              <defs>
                <filter
                  id="Icon_ionic-ios-play-circle"
                  x="0"
                  y="0"
                  width="101"
                  height="101"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset dy="3" />
                  <feGaussianBlur stdDeviation="8.5" result="blur" />
                  <feFlood flood-opacity="0.161" />
                  <feComposite operator="in" in2="blur" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <g
                transform="matrix(1, 0, 0, 1, 0, 0)"
                filter="url(#Icon_ionic-ios-play-circle)"
              >
                <path
                  id="Icon_ionic-ios-play-circle-2"
                  data-name="Icon ionic-ios-play-circle"
                  d="M28.375,3.375a25,25,0,1,0,25,25A25,25,0,0,0,28.375,3.375ZM38.447,28.844,21.957,38.82a.535.535,0,0,1-.805-.469V18.4a.533.533,0,0,1,.805-.469l16.49,9.976A.551.551,0,0,1,38.447,28.844Z"
                  transform="translate(22.13 19.13)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div
        className="relative w-full md:w-[18%] rounded-lg border border-[#000000] shadow-lg overflow-hidden"
        onClick={() => onModalOpen(data)}
      >
        <img
          src={data?.url}
          className="h-[200px] w-full rounded-lg scale-100 aspect-[5/4] cursor-pointer object-cover"
          alt={data?.title}
        />
        <div className="absolute bottom-0 left-0 right-0 px-4 py-3 bg-gray-800 opacity-70 ">
          <h3 className="text-center text-white text-[14px]">{data?.title}</h3>
        </div>
      </div>
    </>
  );
};
export default MediaCardComponent;
