import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loginSlice from "./slices/login-slice/login-slice";
import dashboardSlice from "./slices/dashboard-slice/dashboard-slice";

const RootReducer = combineReducers({
  loginReducer: loginSlice,
  dashboardReducer: dashboardSlice
});
export const store = configureStore({
  reducer: RootReducer,
  // middleware: (getDefaultMiddleware) => {
  //   return getDefaultMiddleware({});
  // },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof RootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
