import { useFormik } from "formik";
import { AddVideoSchema } from "../../validations";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAddVideoDispatcher,
  getUpdateVideoDispatcher,
  getVideoDetailDispatcher,
  getVideosDispatcher,
} from "../../store/slices/dashboard-slice/dashboard-dispatchers";
import { useState } from "react";
import React from "react";
import MediaPickerModal from "../../components/MediaPickerModal";

const AddVideoPage = () => {
  const dispatcher = useAppDispatch();
  const navigate = useNavigate();
  const pageSize = 10;
  const [uploadedImage, setUploadedImage] = useState<string>("");
  const { video_id } = useParams();
  const [draftedData, setDraftedData] = useState({
    title: "",
    thumbnailUrl: "",
    videoUrl: "",
  });
  const [isMediaOpen, setIsMediaOpen] = useState(false);

  React.useEffect(() => {
    localStorage.removeItem("video_drafted");
    if (video_id) {
      getVideoDetail();
    }
    return () => {
      saveDraftedData();
    };
  }, [video_id]);

  const getQuery = (page: number) => {
    return `page=${page}&pageSize=${pageSize}`;
  };

  const saveDraftedData = async () => {
    const draftData = localStorage.getItem("video_drafted");
    if (draftData && !video_id) {
      const res = await dispatcher(
        getAddVideoDispatcher({ ...JSON.parse(draftData), isPublish: false })
      );
      if (res?.payload?.data?.status) {
        toast.success("Video added successfully!", {});
        dispatcher(getVideosDispatcher(getQuery(1)));
      } else {
        toast.error(res?.payload?.error?.message, {});
      }
      localStorage.removeItem("video_drafted");
    }
  };

  const addVideoFormik = useFormik({
    initialValues: {
      title: "",
      thumbnailUrl: "",
      videoUrl: "",
    },
    onSubmit: (values) => {
      if (video_id) {
        onUpdateVideoDetails(values);
      } else {
        onAddNewVideo(values);
      }
    },
    validationSchema: AddVideoSchema,
  });

  const onUpdateVideoDetails = async (values: any) => {
    const res = await dispatcher(
      getUpdateVideoDispatcher({ ...values, _id: video_id })
    );
    if (res?.payload?.data?.status) {
      toast.success("Video updated successfully!", {});
      navigate(-1);
    } else {
      toast.error(res?.payload?.error?.message, {});
    }
  };

  const getVideoDetail = async () => {
    const res: any = await dispatcher(getVideoDetailDispatcher(video_id));
    if (res?.payload?.data?.status) {
      addVideoFormik.setFieldValue(
        "title",
        res?.payload?.data?.data?.vlog?.title
      );
      addVideoFormik.setFieldValue(
        "thumbnailUrl",
        res?.payload?.data?.data?.vlog?.thumbnailUrl
      );
      addVideoFormik.setFieldValue(
        "videoUrl",
        res?.payload?.data?.data?.vlog?.videoUrl
      );
      setUploadedImage(res?.payload?.data?.data?.vlog?.thumbnailUrl);
    }
  };

  const onAddNewVideo = async (values: any) => {
    const res = await dispatcher(
      getAddVideoDispatcher({ ...values, isPublish: false })
    );
    if (res?.payload?.data?.status) {
      localStorage.removeItem("video_drafted");
      toast.success("Video added successfully!", {});
      navigate(-1);
    } else {
      toast.error(res?.payload?.error?.message, {});
    }
  };

  const getFieldsValues = (key: string, value: string) => {
    let OB = draftedData;
    if (!video_id) {
      if (key === "title") {
        OB = { ...OB, title: value };
      }
      if (key === "thumbnailUrl") {
        OB = { ...OB, thumbnailUrl: value };
      }
      if (key === "videoUrl") {
        OB = { ...OB, videoUrl: value };
      }
      setDraftedData(OB);
      localStorage.setItem("video_drafted", JSON.stringify(OB));
    }
  };

  const onSelectedImage = (url: string) => {
    setUploadedImage(url);
    setIsMediaOpen(false);
    addVideoFormik.setFieldValue("thumbnailUrl", url);
  };

  return (
    <>
      <div className="flex-col bg-[#F2F7FF] mb-12">
        <div className="flex-col mx-auto justify-center px-6 mt-12">
          <div className="p-6 bg-white rounded-3xl shadow-[0.0px_4.0px_4.0px_rgba(0,0,0,0.20)]">
            <div className="flex pb-5">
              <h5 className="tracking-tight text-[#121212] font-nunito font-extrabold text-[26px]">
                {video_id ? "Update Video" : "Create Video"}
              </h5>
            </div>
            <div className="mb-6">
              <label className="block text-[#121212] text-[22px] font-semibold font-nunito mb-2">
                Title
              </label>
              <input
                className={`border rounded-[10px] w-full py-4 px-3 text-black leading-tight ${
                  addVideoFormik.touched.title && addVideoFormik.errors?.title
                    ? "border-red-500"
                    : "border-[#000000]"
                }`}
                id="title"
                type="text"
                placeholder="Title"
                onChange={(event) => {
                  getFieldsValues("title", event?.target?.value);
                  addVideoFormik.setFieldValue("title", event?.target?.value);
                }}
                value={addVideoFormik.values?.title}
                onBlur={addVideoFormik.handleBlur}
              />
              {addVideoFormik.touched.title && addVideoFormik.errors?.title ? (
                <p className="text-red-500 text-sm italic font-normal font-nunito">
                  {addVideoFormik.errors?.title}
                </p>
              ) : null}
            </div>
            <div className="mb-6">
              <label className="block text-[#121212] text-[22px] font-semibold font-nunito mb-2">
                Video link
              </label>
              <input
                className={`border rounded-[10px] w-full py-4 px-3 text-black leading-tight ${
                  addVideoFormik.touched.videoUrl &&
                  addVideoFormik.errors?.videoUrl
                    ? "border-red-500"
                    : "border-[#000000]"
                }`}
                id="videoUrl"
                placeholder="Add Video link here..."
                onChange={(event) => {
                  getFieldsValues("videoUrl", event?.target?.value);
                  addVideoFormik.setFieldValue(
                    "videoUrl",
                    event?.target?.value
                  );
                }}
                value={addVideoFormik.values?.videoUrl}
                onBlur={addVideoFormik.handleBlur}
              />
              {addVideoFormik.touched.videoUrl &&
              addVideoFormik.errors?.videoUrl ? (
                <p className="text-red-500 text-sm italic font-normal font-nunito">
                  {addVideoFormik.errors?.videoUrl}
                </p>
              ) : null}
            </div>
            <div className="mb-6">
              <label className="block text-[#121212] text-[22px] font-semibold font-nunito mb-2">
                Featured Image
              </label>
              <label
                onClick={() => {
                  setIsMediaOpen(true);
                }}
                className={`border rounded-[10px] w-full bg-white text-center min-h-[270px] py-4 px-4 flex flex-col items-center justify-center cursor-pointer mx-auto font-nunito m-4 ${
                  addVideoFormik.touched.thumbnailUrl &&
                  addVideoFormik.errors?.thumbnailUrl
                    ? "border-red-500"
                    : "border-[#000000]"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-8 mb-6 fill-gray-400"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M22 13a1 1 0 0 0-1 1v4.213A2.79 2.79 0 0 1 18.213 21H5.787A2.79 2.79 0 0 1 3 18.213V14a1 1 0 0 0-2 0v4.213A4.792 4.792 0 0 0 5.787 23h12.426A4.792 4.792 0 0 0 23 18.213V14a1 1 0 0 0-1-1Z"
                    data-original="#000000"
                  />
                  <path
                    d="M6.707 8.707 11 4.414V17a1 1 0 0 0 2 0V4.414l4.293 4.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 0 0 1.414 1.414Z"
                    data-original="#000000"
                  />
                </svg>
                <p className="text-[#00000059] text-[22px] font-nunito font-bold">
                  <span className="text-[#007bff]">Choose file</span> to upload
                  image
                </p>
                <p className="text-xs text-[#00000059] mt-2 font-nunito">
                  PNG, JPG are Allowed.
                </p>
                <img
                  className="object-cover h-auto w-1/3"
                  src={uploadedImage}
                />
              </label>
              {addVideoFormik.touched.thumbnailUrl &&
              addVideoFormik.errors?.thumbnailUrl ? (
                <p className="text-red-500 text-sm italic font-normal font-nunito">
                  {addVideoFormik.errors?.thumbnailUrl}
                </p>
              ) : null}
            </div>
            <div className="flex items-center justify-center w-full">
              <div className="flex justify-start">
                <button
                  onClick={() => addVideoFormik.handleSubmit()}
                  className="bg-gradient-to-r from-[#14ADD6] to-[#384295] p-[1px] rounded-[10px] focus:outline-none w-[200px]"
                >
                  <span className="bg-gradient-to-r from-[#14ADD6] to-[#384295] font-bold block text-white hover:text-[#384295] px-4 py-[10px] bg-white rounded-[10px] hover:from-white hover:to-white font-nunito cursor-pointer">
                    Upload
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {isMediaOpen ? (
          <MediaPickerModal
            onClicked={() => {
              setIsMediaOpen(false);
            }}
            onSelected={(url: string) => {
              onSelectedImage(url);
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default AddVideoPage;
