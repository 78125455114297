import { Endpoints } from "../../../enpoints/v1"
import AxiosService from "../../../client-interceptor"

const signIn = async (body: SignInRequestBodyType) => {
  return await AxiosService.post(Endpoints.SignIn, body)
}

const logOut = async () => {
  // return await AxiosService.post(Endpoints.Logout, {})
}

export default { signIn, logOut }
