import { Outlet } from "react-router-dom";
import AdminSideBar from "../components/sidebar";
import AdminNavBar from "../components/navbar";
import { RotatingLines } from "react-loader-spinner";
import { useAppSelector } from "../store/hooks";
import { getLoadingSelector } from "../store/slices/dashboard-slice/dashboard-selectors";

const AdminLayout = () => {

	const loading = useAppSelector(getLoadingSelector)

	return (
		<div className="flex bg-[#F2F7FF]">
			<AdminSideBar />
			<div className="flex-col w-4/5 min-h-screen">
				<AdminNavBar />
				<Outlet />
				{ loading ? <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 -mt-10">
					<RotatingLines
						visible={ true }
						strokeWidth="5"
						animationDuration="0.75"
						ariaLabel="rotating-lines-loading"
					/>
				</div> : null }
			</div>
		</div>
	);
};

export default AdminLayout;
